import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const PreviewModal = props => {
  const { fileName } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <img
        src={`/api/items/image/${fileName}`}
        height="30px"
        width="30px"
        onClick={toggle}
        alt={fileName}
      />

      <Modal size={'xl'} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Image Preview</ModalHeader>
        <ModalBody>
          <img
            src={`/api/items/image/${fileName}`}
            height="100%"
            width="100%"
            onClick={toggle}
            alt={fileName}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PreviewModal;

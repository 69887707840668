import React, { useState } from 'react';
import ViewForm from './../components/ViewForm';
import AddForm from './../components/AddForm';

function LandingPage() {
  var [items, setItems] = useState([]);
  const callback = addItem => {
    setItems([addItem]);
  };

  return (
    <>
      <AddForm callback={callback} />
      <ViewForm items={items} />
    </>
  );
}

export default LandingPage;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';

import Notfound from './views/404';
import LandingPage from './views/LandingPage';
import 'bootstrap/dist/css/bootstrap.css';

import { Spinner } from 'reactstrap';

const suspense = <Spinner style={{ width: '3rem', height: '3rem' }} />;

const routing = (
      <Suspense fallback={suspense}>
        <Router>
          <Switch>
            <Route exact path="/" component={withRouter(LandingPage)} />
            <Route component={Notfound} />
          </Switch>
        </Router>
      </Suspense>
);

ReactDOM.render(routing, document.getElementById('root'));

serviceWorker.unregister();

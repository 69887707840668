import React, { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Row,
  FormFeedback,
} from 'reactstrap';

const axios = require('axios');

function AddForm(props) {
  const [formState, setFormState] = useState({});
  const [errors, setErrors] = useState({});

  const handleFileChange = e => {
    const newFormState = formState;
    newFormState[e.target.name] = e.target.files[0];
    setFormState(newFormState);
  };
  const handleChange = e => {
    const newFormState = formState;
    newFormState[e.target.name] = e.target.value;
    setFormState(newFormState);
  };
  const addItem = () => {
    const data = new FormData();
    Object.keys(formState).forEach(function(k, index) {
      if (k === 'file') return;
      data.append(k, formState[k]);
    });
    data.append('file', formState['file']); // Append at the end
    axios
      .post('/api/items/add', data)
      .then(function(response) {
        setFormState({
          title: '',
          description: '',
          quantity: '',
          file: null,
        }); // Empty the State
        console.log(response.data);
        props.callback(response.data.doc);
      })
      .catch(function(err) {
        if (err.response.data.errors) setErrors(err.response.data.errors);
      });
  };
  return (
    <>
      <div>
        <h3>Add New Item</h3>
        <Form>
          <Row>
            {errors.msg ? (
              <Col md="12">
                <h3 className="text-center ">{errors.msg}</h3>
              </Col>
            ) : (
              ''
            )}

            <Col md="6">
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Title"
                  value={formState.title}
                  invalid={errors.title ? true : false}
                  onChange={handleChange}
                />
                <FormFeedback>{errors.title ? errors.title : ''}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Description"
                  value={formState.description}
                  invalid={errors.description ? true : false}
                  onChange={handleChange}
                />
                <FormFeedback>{errors.description ? errors.description : ''}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="photo">File</Label>
                <Input
                  type="file"
                  name="file"
                  id="photo"
                  value={formState.file}
                  invalid={errors.file ? true : false}
                  onChange={handleFileChange}
                />
                <FormText color="muted">Upload Image of the Item</FormText>
                <FormFeedback>{errors.file ? errors.file : ''}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <Label for="quantity">Quantity</Label>
              <Input
                type="number"
                name="quantity"
                id="quantity"
                placeholder="Quantity"
                value={formState.quantity}
                invalid={errors.quantity ? true : false}
                onChange={handleChange}
              />
              <FormFeedback>{errors.quantity ? errors.quantity : ''}</FormFeedback>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <Button onClick={addItem}>Add Item</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default AddForm;

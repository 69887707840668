import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import PreviewModal from './PreviewModal';

const axios = require('axios');

function ViewForm(props) {
  const [items, setItems] = useState(props.items ? props.items : []);
  useEffect(() => {
    // Make a request for a user with a given ID
    axios
      .get('/api/items/list')
      .then(function(response) {
        setItems(i => {
          return [...i, ...response.data];
        });
      })
      .catch(function(error) {
        // Do Nothing
      });
  }, []);

  useEffect(() => {
    setItems(i => {
      return [...i, ...props.items];
    });
  }, [props.items]);

  return (
    <>
      <div>
        <h3>Item List</h3>
        <Table striped responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Description</th>
              <th>Image</th>
              <th>Quantity</th>
              <th>Date Added</th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((value, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{value.title}</td>
                    <td>{value.description}</td>
                    <td>
                      <PreviewModal fileName={value.fileName} />
                    </td>
                    <td>{value.quantity}</td>
                    <td>{value.date}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default ViewForm;
